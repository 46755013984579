/* eslint-disable no-underscore-dangle */
import actions from './actions'

const initialState = {
  invoiceList: null,
  searchInvoice: null,
  invoicepdfLocationUrl: null,
  loading: false,
  creating: false,
  adding: false,
  openproforma: false,
  displayCreateInvoiceDrawer: false,
  displayEditInvoiceDrawer: false,
}

export default function invoiceReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.ADD_ITEM:
      return { ...state, selectedData: action.payload, displayCreateInvoiceDrawer: true }
    case actions.HIDE_ITEM:
      return { ...state, selectedData: null, displayCreateInvoiceDrawer: false }
    case actions.SHOW_UPDATE:
      return {
        ...state,
        selectedData: action.payload.invoiceInfo,
        displayEditInvoiceDrawer: true,
      }
    case actions.HIDE_UPDATE:
      return { ...state, displayEditInvoiceDrawer: false }
    default:
      return state
  }
}

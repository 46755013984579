const actions = {
  SET_STATE: 'invoice/SET_STATE',
  LOAD_ALL: 'invoice/LOAD_ALL',
  ADD_ITEM: 'invoice/ADD_ITEM',
  HIDE_ITEM: 'invoice/HIDE_ITEM',
  VIEW_PO: 'invoice/VIEW_PO',
  SHOW_UPDATE: 'invoice/SHOW_UPDATE',
  HIDE_UPDATE: 'invoice/HIDE_UPDATE',
  SINGLE_INVOICE: 'invoice/SINGLE_INVOICE',
  UPDATE_INVOICE: 'invoice/UPDATE_INVOICE',
  CREATE_INVOICE: 'invoice/CREATE_INVOICE',
}

export default actions

const actions = {
  SET_STATE: 'bill/SET_STATE',
  LOAD_ALL: 'bill/LOAD_ALL',
  ADD_ITEM: 'bill/ADD_ITEM',
  HIDE_ITEM: 'bill/HIDE_ITEM',
  SHOW_UPDATE: 'bill/SHOW_UPDATE',
  HIDE_UPDATE: 'bill/HIDE_UPDATE',
  SINGLE_QUOTATION: 'bill/SINGLE_QUOTATION',
  UPDATE_QUOTATION: 'bill/UPDATE_QUOTATION',
  VIEW_PO: 'bill/VIEW_PO',
  CREATE_QUOTATION: 'bill/CREATE_QUOTATION',
  SINGLE_BILL: 'bill/SINGLE_BILL',
  UPDATE_BILL: 'bill/UPDATE_BILL',
}

export default actions

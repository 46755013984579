import { all } from 'redux-saga/effects'
import user from './user/sagas'
import menu from './menu/sagas'
import settings from './settings/sagas'
import staff from './staff/sagas'
import accounts from './accounts/sagas'
import invoice from './invoice/sagas'
import singleaccounts from './singleaccounts/sagas'
import quotation from './quotation/sagas'
import bill from './bill/sagas'

export default function* rootSaga() {
  yield all([
    user(),
    menu(),
    settings(),
    staff(),
    accounts(),
    invoice(),
    quotation(),
    singleaccounts(),
    bill(),
  ])
}

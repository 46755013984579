const actions = {
  SET_STATE: 'quotation/SET_STATE',
  LOAD_ALL: 'quotation/LOAD_ALL',
  ADD_ITEM: 'quotation/ADD_ITEM',
  HIDE_ITEM: 'quotation/HIDE_ITEM',
  SHOW_UPDATE: 'quotation/SHOW_UPDATE',
  HIDE_UPDATE: 'quotation/HIDE_UPDATE',
  SINGLE_QUOTATION: 'quotation/SINGLE_QUOTATION',
  UPDATE_QUOTATION: 'quotation/UPDATE_QUOTATION',
  VIEW_PO: 'quotation/VIEW_PO',
  CREATE_QUOTATION: 'quotation/CREATE_QUOTATION',
}

export default actions

/* eslint-disable no-underscore-dangle */
import actions from './actions'

const initialState = {
  billInfoList: null,
  quotationList: null,
  searchQuotation: null,
  quotationDetails: null,
  quotationpdfLocationUrl: null,
  loading: false,
  creating: false,
  adding: false,
  openproforma: false,
  displayCreateQuotationDrawer: false,
  displayEditBillDrawer: false,
}

export default function billReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.ADD_ITEM:
      return { ...state, selectedData: action.payload, displayCreateQuotationDrawer: true }
    case actions.HIDE_ITEM:
      return { ...state, selectedData: null, displayCreateQuotationDrawer: false }
    case actions.SHOW_UPDATE:
      return {
        ...state,
        selectedData: action.payload.billInfo,
        displayEditBillDrawer: true,
      }
    case actions.HIDE_UPDATE:
      return { ...state, displayEditBillDrawer: false }
    default:
      return state
  }
}
